<template>
  <section ref="summaryList">
    <h1>{{ $t('summary.title.main', { patientName: user.username }) }}</h1>
    <v-container grid-list-md fluid>
      <v-row dense>
        <v-col cols="12">
          <v-card ref="summary" id="agenda">
            <v-card-title class="headline">
              {{ $t('summary.title.sleepAgenda') }}
            </v-card-title>
            <v-card-actions>
              <v-btn href="#report" color="secondary" left text>
                <v-icon>mdi-file-table-outline</v-icon>
                {{ $t('summary.title.report') }}
              </v-btn>
              <v-spacer /><v-spacer />
              <v-select
                v-model="selectDatesRange.value"
                :items="datesRange"
                persistent-hint
                single-line
              ></v-select>
            </v-card-actions>
            <v-card-text>
              <insolux-summary
                :error-message="errorMessage"
                :items="filteredItems"
                :loading.sync="loading"
              >
              </insolux-summary>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mt-2" style="page-break-before: always;">
        <v-col>
          <v-card ref="report" id="report">
            <v-card-title class="headline">
              {{ $t('summary.title.report') }}
            </v-card-title>
            <v-card-actions>
              <v-btn href="#agenda" color="secondary" left text>
                <v-icon>mdi-calendar</v-icon>
                {{ $t('summary.title.sleepAgenda') }}
              </v-btn>
            </v-card-actions>
            <v-card-text>
              <insolux-report
                :error-message="errorMessage"
                :items="filteredItems"
                :scores="scores"
                :loading.sync="loading"
              >
              </insolux-report>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="d-print-none">
        <v-col>
          <footer class="text-center" style="margin-top:10px;">
            <v-btn
              :to="{
                name: 'DashboardPatient',
                params: { patientId: patientId },
              }"
              color="secondary"
              depressed
            >
              {{ $t('dashboard.doctor.buttons.return') }}
            </v-btn>
          </footer>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PatientSummary',

  components: {
    InsoluxSummary: () => import('@/components/Survey/Summary/InsoluxSummary'),
    InsoluxReport: () => import('@/components/Survey/Summary/InsoluxReport'),
  },

  props: {
    patientId: {
      required: true,
    },
  },

  data: () => ({
    errorMessage: '',
    items: [],
    scores: [],
    loading: false,
    title: '',
    selectDatesRange: { value: 'all' },
  }),

  computed: {
    ...mapGetters(['user', 'results']),
    datesRange() {
      let dates = []
      if (this.items.length) {
        let startDate = new Date(this.items[0].real_date)
        let endDate = new Date(this.items[this.items.length - 1].real_date)
        let currentDate = startDate

        dates.push({ text: this.$t('summary.selectAllPeriods'), value: 'all' })

        while (currentDate <= endDate) {
          let nextDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate() + 7
          )

          if (nextDate <= endDate) {
            dates.push({
              text:
                currentDate.toLocaleDateString(navigator.language || 'fr-FR') +
                '-' +
                nextDate.toLocaleDateString(navigator.language || 'fr-FR'),
              value: currentDate + '-' + nextDate,
            })
          } else {
            dates.push({
              text:
                currentDate.toLocaleDateString(navigator.language || 'fr-FR') +
                '-' +
                endDate.toLocaleDateString(navigator.language || 'fr-FR'),
              value: currentDate + '-' + endDate,
            })
          }

          currentDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate() + 7
          )
        }
      }

      return dates
    },
    filteredItems() {
      if (this.selectDatesRange && this.selectDatesRange.value !== 'all') {
        let range = this.selectDatesRange.value.split('-')
        let startDate = new Date(range[0])
        let endDate = new Date(range[1])
        return this.items.filter(a => {
          var date = new Date(a.real_date)
          return date >= startDate && date <= endDate
        })
      }
      return this.items
    },
  },

  created() {
    this.loading = true
    Promise.all([
      this.loadUser({ id: this.patientId }),
      this.loadPatientResults(this.patientId),
    ]).then(this.loadResults)
  },

  methods: {
    ...mapActions(['loadUser', 'loadPatientResults']),
    loadResults() {
      this.items.splice(0, this.items.length)
      this.scores.splice(0, this.scores.length)
      Object.keys(this.results).forEach(key => {
        // TODO: for now only working for Insolux needs refactoring !!!
        this.items.push(this.results[key]['INS_QUOTIDIEN'])
        this.scores.push(this.results[key]['SCORES'])
      })
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'src/assets/style/style';

#summary__patient {
  > section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media print {
  > section {
    padding: 20px;
    position: fixed;
    height: 100%;
    left: 0;
    top: 0;
    border: none;
  }
}
</style>
